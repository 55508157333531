<button mat-icon-button [matMenuTriggerFor]="notificationMenu" class="c-mat-icon-badge"
        *ngIf="notifications.today.concat(notifications.earlier).length">
  <mat-icon [matBadge]="notReadNotificationsCount"
            [matBadgeHidden]="!notReadNotificationsCount"
  >notifications
  </mat-icon>
</button>

<button mat-icon-button
        routerLink="/notifications"
        *ngIf="!notifications.today.concat(notifications.earlier).length">
  <mat-icon>notifications</mat-icon>
</button>

<mat-menu #notificationMenu="matMenu" class="c-mat-menu-panel">
  <ng-template matMenuContent let-name="name">
    <div class="notification-content">
      <div class="c-mat-item" mat-menu-item
           *ngFor="let n of notifications.today.concat(notifications.earlier)"
           (click)="navigateToNotificationList(n, $event)">
        <div fxLayoutAlign="space-between" class="notification-status">
          <span>{{!n.is_read ? 'New' : ''}}</span>
          <span>{{n.created|date:'short'}}</span>
        </div>
        <div class="title">
          {{n.title}}
        </div>
        <div class="sub-title" [class.redirect-link]="n?.extra_params?.origin_id">
          {{n.message}}
        </div>
      </div>
    </div>
  </ng-template>
  <div class="see-all">
    <a routerLink="/notifications">See All Notifications</a>
  </div>
</mat-menu>
