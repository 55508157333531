import { Component, OnInit } from "@angular/core";
import { SupportService } from "../../services/support.service";
import { SettingsService } from "../../services/settings/settings.service";
import { switchMap } from "rxjs/operators";

const faqContent = [
  {
    title: "WHAT IS TOKENTORY?",
    text: "Tokentory is a new option for a world that is in transition. The age of influencers is upon us, with some individuals boasting followings that rival entire country populations. Influencers - it’s time to cash in on your popularity, you deserve your own currency - a social currency. Fans- you are no longer just followers - we spend so much time following people on social media, it’s time we gained something more for our time and support. By buying your favorite artist or influencer’s Tokens, you now directly benefit from their fame and success. Second, you can be closer than ever before to the things you love and as a token holder. Third, by buying Star Tokens, you are showing the world what has value and what is BIG! You will be defining the values of our time. \n We’ve grown tired of people and companies telling us what’s valuable and what’s not - so we created a platform that allows anyone the opportunity to capture the value they give to the world, and let the people decide.",
  },
  {
    title: "HOW DOES TOKENTORY WORK?",
    text: "Tokentory is a token factory and marketplace powered by blockchain. It is built to democratize participation. Tokentory is a marketplace for celebrities, brands, venues and causes to tokenize their social demand, building a direct bond and easily granting access, preference, and participation to loyal followers. Tokentory enables people to participate in and benefit from the growth of their favorite artists or influencers emotionally connected. We’ve all had that “I followed them before they blew up!” moment in our lives when talking about someone who’s impacted us, now we can prove it. Loyal followers can now join in the success of what or who they are passionate about. \n On Tokentory, Tokens can be created, traded, or spent. Each Token has it’s own overview page, with its very own market and Offerings available. Each account has its very own Ethereum wallet, which blends the blockchain with the simplicity of any normal website.",
  },
  {
    title: "WHAT IS A TOKEN?",
    text: "The individuals, brands, places and social movements that are tokenized on the Tokentory platform are create their own Tokens. These Tokens have their own unique name given by the creator so that they can be easily found. The​ ​token itself​ ​is​ ​a​ ​digital commodity,​ ​bearing​ ​value​ ​based​ ​on​ ​its​ ​underlying​ ​social demand. Like most other cryptocurrencies, each token has a fixed supply - meaning as more people become interested in it, the value becomes higher. \n Tokens can be bought and sold by anyone on the Token Market, meaning users can trade Tokens based on who they think might be the next big thing. These Tokens can also be spent on exclusive Offerings created for that Token. This is a way for Creators to show love to their most loyal followers, while at the same time benefiting themselves by regaining some of their Tokens which can later be resold.",
  },
  {
    title: "HOW TO BUY STAR TOKENS?",
    text: "You can buy Tokens on the Tokentory.com website or the Tokentory App which will be available on IOS and Android soon. Simply log in, search the Token Directory for any Token you’re interested in, and purchase them from the Token Market if they’re for sale. Creators also have customized links to their token page, so if your favorite artist has a Token, just follow the link they share to get to their Token page. Our payment system integration removes the hassle of having to exchange USD for ETH before buying - simply purchase your Tokens directly with PayPal.",
  },
  {
    title: "HOW TO SELL STAR TOKENS",
    text: "List your Tokens for sale as easy as you bought them. On the same Token Market, simply click “SELL” and name your price. Once another user wishes to purchase your tokens, the tokens will be transferred to their account while the proceeds will be delivered to your linked PayPal account.",
  },
  {
    title: "WHAT IS A TOKEN OFFERING?",
    text: "A Token Offering is a product or service offered by the Token Creator exclusively for holders of that Token. These can be things such as tickets to events, merchandise, etc. that can only be purchased with the Token. Once an Offering is purchased, the price in Tokens is transferred from the buyer’s wallet to the creators. The buyer will then find a receipt for the Offering in their wallet, while the seller will receive the tokens which can then be resold on the market. \n The receipt includes the blockchain transaction hash, meaning it cannot be duplicated or deleted. This add a new layer of security – especially regarding event ticket offerings, where the receipt can be scanned upon entry to ensure proof of purchase.",
  },
  {
    title: "HOW DO I CREATE A TOKEN?",
    text: "Tokens can be created in a few clicks from the Token Factory page. Simply input some details about your token such as a unique name, Image, total amount, decimals (the lowest fraction of one token that can be purchased) etc. and Click “CREATE TOKEN” within a few minutes your token will become active on the blockchain and appear in your wallet ready for sale. All you need to do is spread awareness of your new Token by sharing your specialized Token Page link to your followers. \n If you are a prominent figure, or simply want your token to stand out from the rest, you can request verification upon creation. Once the review process is complete and approved, your token will receive a verification badge – similar to Twitter or Instagram verified accounts - showing your buyers that this is indeed the correct and only token that represents you.",
  },
  {
    title: "HOW DO I CREATE A TOKEN OFFERING?",
    text: "Similar to creating a Token, a Token Offering can be created from the Token Factory tab in a few clicks. First you must have created a Token. From there, open the desired token factory page and click “CREATE OFFERING”. You will be required to input details such as the unique name, available amount, cost, Offering type (Event, Item, etc.) as well as a description and Image. Once created, sit back and wait for your Token holders to be notified and become interested. You can also share the specialized link to your Offering on your social media pages. \n Once users begin to purchase your offering, you will be able to manage the orders from the Factory page. You will be able to export the order details, which will contain buyer comments and addresses for shipping etc. As well have the ability to validate buyer receipts using your phone camera – especially useful for Ticketed Event Offerings.",
  },
];

@Component({
  selector: "app-faq",
  templateUrl: "./faq.component.html",
  styleUrls: ["./faq.component.scss"],
})
export class FaqComponent implements OnInit {
  faq: { title: string; text: string }[] = [];
  constructor(
    private supportService: SupportService,
    private settings: SettingsService
  ) {
    this.settings.settings$.subscribe((el) => {
      faqContent[1].text = faqContent[1].text.replace("Ethereum", el.title);
    });
  }

  ngOnInit(): void {
    this.supportService.getFAQ().subscribe(
      (res) => {
        this.faq = res.results?.lenght ? res.reuslts : faqContent;
      },
      (err) => (this.faq = faqContent)
    );
  }

  scroll(index) {
    document.getElementById(index).scrollIntoView();
  }
}
