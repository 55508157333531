  <app-public-toolbar [showSignupLink]="true"></app-public-toolbar>
  <div fxLayout="row">
    <div class="tokenomics-menu" fxHide.xs>
      <div fxLayout="row" fxLayoutAlign="space-between end">
        <img src="assets/images/tokn_logo.svg" alt="Tokentory Logo">
      </div>
      <mat-divider class="menu-divider"></mat-divider>
      <mat-selection-list [multiple]="false" dense class="left-menu-list">
        <mat-list-option class="main-title-list-item" (click)="scroll(blockchainbackend)">Blockchain-Backed</mat-list-option>
        <mat-list-option (click)="scroll(digitalScarcity)">Digital Scarcity</mat-list-option>
        <mat-list-option (click)="scroll(rebuilding)">Rebuilding Trust in the Blockchain</mat-list-option>

        <mat-list-option class="main-title-list-item" (click)="scroll(introduction)">Introduction to Tokentory</mat-list-option>
        <mat-list-option (click)="scroll(createEco)">Create an “eco-system” by minting your own token</mat-list-option>
        <mat-list-option (click)="scroll(exactlyToken)">What Exactly is a Token?</mat-list-option>
        <mat-list-option (click)="scroll(sellYourTokens)">What Exactly is a Token?Sell your tokens</mat-list-option>
        <mat-list-option (click)="scroll(tradeTokens)">Trade tokens</mat-list-option>
        <mat-list-option (click)="scroll(sellRealGoods)">Sell real goods and services</mat-list-option>
        <mat-list-option (click)="scroll(whatIsOffering)">What Exactly is an Offering?</mat-list-option>
        <mat-list-option (click)="scroll(offeringCycle)">Token Creation and Offering Cycle</mat-list-option>
      </mat-selection-list>
    </div>

    <div class="tokenomics-container">
      <div class="tokenomics-content">
        <h1 class="tokenomics-title">Tokenomics</h1>
        <h2 class="tokenomics-sub-title" #blockchainbackend>Blockchain-Backed</h2>
        <h2 class="tokenomics-sub-title__inner" #digitalScarcity>Digital Scarcity</h2>
        <p>For the first time in digital history, we are able to create a product that, without
          being managed by an overlooking entity, can connect an infinite number of users
          while being tamper-proof, counterfeit-proof, and validated traceability to its creation
          (i.e. we can now create something on the internet with a definite limited supply,
          without the need for third-party verification). This is all possible because of
          blockchain technology. Blockchain technology is the verification of transactions
          across a network of computers using cryptographic hashing algorithms to take
          account of an object from its origin, which, in turn, creates a distributed ledger of
          balances. This ledger cannot be tampered with because each transaction hash must
          correspond to its prior hash, ultimately tracing back to the first transaction of the
          object. So, if someone tries to make a change without the consensus of the entire
          network, the hash does not match and is discarded.
        </p>

        <p>
          Money has six characteristics - durability, portability, divisibility, uniformity, and
          acceptability. Because the blockchain is not controlled by a single entity, but by a
          network of computers working in competition, it allows for <b>durability</b>
          (tamper/delete proof), <b>portability</b> (digital wallets accessible anywhere with access
          to the blockchain network), <b>divisibility</b> (any object on the blockchain can be
          programmed to be divisible in its creation, by up to 18 decimal points if desired),
          <b>uniformity</b> (fungible objects on the blockchain can be created and one will equal
          the other), and <b>accessibility</b> (the distributed network is censorship proof, and
          because the data is distributed across the network, there is no single point of
          failure. Along with these characteristics is the ability to create a <b>limited supply</b>.
          When an object is programmed on the blockchain, its origin must be a specified
          total amount, or rules of that object’s future creation, without the possibility of
          these rules to be changed later). Being able to achieve all these characteristics,
          along with scarcity – without the need for third-party oversight – means that for the
          first time we can create a digital asset comparable to gold. This is the reason that
          so many “cryptocurrencies” have made use of this technology. However, there is
          still one problem: mass accessibility.
        </p>
        <h2 class="tokenomics-sub-title__inner" #rebuilding>Rebuilding Trust in the Blockchain</h2>
       <p>
         The word “blockchain” seems to confuse or even intimidate most people. In fact,
         many companies started to take advantage of the mystery behind the concept,
         creating buzzwords with the hope of boosting hype and gaining investments by
         those chasing the “next big thing”. Yet, there was no real value under the surface.
         We can see from the aftermath of the crypto bubble burst that this has been
         extremely detrimental to the progress of this technology. Many people were
         deceived by the false promises of great technological breakthroughs, making them
         wary and turning them away from any use of the word “blockchain” or
         “cryptocurrencies”. This is saddening because this mindset has limited the benefits
         of blockchain to only those who are willing to look past the stigma and put in the
         time to educate themselves about cryptocurrencies. We at Tokentory still have
         hope, and we know that if applied correctly, blockchain will, indeed, help us create a
         better future. Our mission is to help society regain the trust that was broken by the
         crypto bubble.
       </p>

        <p>
          In order to provide a solution, we first have to look at the problem. What caused the
          bubble? What caused it to burst? Why are 90% of the cryptocurrencies basically
          extinct after only a couple years? Why are the ones that are still around today still
          around? The answer comes down to value. The sudden rise of blockchain outgrew
          mass understanding. The hype brought great promises – floods of problems that
          can be fixed by just “using blockchain”. People began believing this false hype
          without understanding the real application or value that blockchain brings. One by
          one these promises fizzled out, and people slowly realized that blockchain isn’t here
          to solve everything. But by then, it was too late. The curtain was pulled back and
          hundreds of companies and cryptocurrencies were left naked, showing no value
          other than the hype they generated. However, for those of us who stayed to look
          around after the smoke cleared, the bubble burst helped us uncover exactly where
          blockchain’s value lies: hype. Why were so many crypto companies so valuable pre-
          bubble? People believed in them. Why did they die out? People found out the crypto
          companies couldn’t live up to their promises. Why is Bitcoin still so valuable today?
          It made no promises – people know about it and the word continues to spread. The
          equation is simple: scarce goods that are highly sought-after gain value.
        </p>
        <p>
          Blockchain provides scarcity, people provide demand. Instead of following the lead
          of so many failed crypto companies, we decided on a different approach. We aren’t
          going to sell you on how we are a company using Blockchain to solve world hunger,
          or disrupt the airline industry. In fact, we are doing just the opposite. It is not up to
          us to decide what gives blockchain objects their value, all we know is that they are
          scarce and immutable. Why is gold so valuable? It’s scarce, and everyone seemed
          to agree on the fact that it’s desirable. Together, people determine what has value.
          When someone creates a song that people enjoy, it becomes valuable. When a book
          is able to influence the lives of many, it becomes valuable. People and things
          become valuable not by saying that they are valuable, but by influencing the lives
          of many.
        </p>
        <p>
          So instead of sitting around trying to convince people our product has value, we
          decided it’s best to focus on making it as easy as possible for others to share the
          value they already bring to the world.
        </p>
        <h2 class="tokenomics-sub-title__inner">
          Current Societal Shift
        </h2>

        <p>
          Whether you embrace it or fear it, one thing is for certain, and that is that we are in
          the middle of a huge societal shift. With the rapid growth in technology, humans are
          interacting today in ways much different than even only a couple decades ago. In
          the short span of 15 years, social media companies like YouTube, Facebook,
          Instagram, and Twitter have carried the world into a new age of “influencers” –
          ordinary people who have amassed followings that rival the populations of entire
          countries – by simply delivering interesting content that the masses can relate to or
          desire. Throughout human history, never has this feat been so easily achievable.
        </p>
        <p>
          Not only do these individuals have millions of followers, but they are able to engage
          with them directly 24/7 and give them unrestricted access to their daily lives.
          However, monetizing these followings has been somewhat limited, falling back on
          familiar sources of revenue: sponsorships and promotion.
        </p>
        <p>
          We at Tokentory believe the point has been reached where these internet
          influencers have the social demand to support their own mini economies. We are
          providing the means of creating a scarce currency, and they give it value. A next
          step in the evolution of social media: <b>Social Currency</b>.
        </p>
        <h2 class="tokenomics-sub-title" #introduction>
          Introduction to Tokentory
        </h2>
        <p>
          With the overall objective of making blockchain as simple as using the internet, we
          plan to dismantle the confusion surrounding the blockchain space and allow for
          even novice internet users to reap technology’s benefits. We will accomplish this by
          pushing all blockchain functions to the backend, then providing a “simplicity bridge”
          on all possible blockchain interactions, including the naming system, payment
          system, blockchain object creation, management, exchange, and search. Users
          visiting the site will be able to create a blockchain account with a human readable
          name similar to an email address (e.g. username.tokenory.eth). From there, they
          will be given the option to create and deploy “tokens” with a couple clicks and
          inputs, along with “offerings”, which are opportunities to spend said tokens. These
          will  also both be assigned human readable names, providing an easy way for
          verification and search. When users wish to buy or sell their tokens to other users, a
          fiat-token payment feature provides users the ability to buy tokens directly, using
          their USD. These components together create a fully functional market built around
          a created currency. It allows for the creation of this currency tied to an entity, the
          exchange, the purchase of currency from fiat, the creation/sale/purchase of items
          using the new currency, and then the sale of that currency back to fiat (given there
          is someone interested in the trade). This is all done with the simplicity of any other
          website we use today, except for the fact that it’s powered by the distributed
          blockchain network. This means there is no party that can tamper with or delete the
          objects once they are deployed. Even if the site shuts down, moves, or gets hacked,
          the blockchain creations will continue to exist and remain out of reach.
        </p>
        <h2 class="tokenomics-sub-title__inner" #createEco>Create an “eco-system” by minting your own token</h2>
        <p>
          A popular term in blockchain literature is “eco-system.” This is shorthand for
          economic system (not to be confused with an environmental ecosystem). An easy-
          to-understand eco-system is the ubiquitous state fair, where attendees buy tickets
          for drinks, food, and games at a single booth to help control and keep track of the
          money and expenses and to reduce losses caused by fraud. The tickets can be
          exchanged for items or events inside the fair. Blockchain technology allows for the
          creation of online economic systems similar to state fairs in concept, but much
          larger and more sophisticated in application. If you have or can develop a following
          of people, such as a group of social media followers or fans or readers of your
          journalism, tokentory.com allows for an easy, inexpensive way to generate a large
          amount of cash quickly by creating a token and a simple business plan to sell items
          to buyers of your tokens (said items include music, tickets to events, or anything
          else).
        </p>
        <h2 class="tokenomics-sub-title__inner" #exactlyToken>What Exactly is a Token?</h2>
        <p>
          Similarly to an NFT, a token (or ERC-20 token) is a virtual currency that resides on
          the blockchain and can represent an asset or utility. The fundamentals of a token
          are that once it is created, it cannot be changed or deleted. Due to the nature of the
          blockchain, a token can always be traced back to its origin, removing the possibility
          of counterfeiting, loss, or misrepresentation. A set amount of tokens is determined
          by the creator before creation and cannot be modified after; this makes the token a
          scarce good. Tokens differ from NFTs because they are fungible, meaning tokens
          hold the same value as others and can be traded for other currencies, or spent on
          goods and services similar to the US dollar. The value a token has is fully dependent
          on the perceived value an individual gives it. This value can reflect speculation or
          the token’s utility.
        </p>
        <h2 class="tokenomics-sub-title__inner" #sellYourTokens>
          Sell your tokens to the public, who can either use those tokens or
          resell them on the token marketplace.
        </h2>
        <p>
          Tokens are offered for sale initially to the public on tokentory.com in an ITO (Initial
          Token Offering). Such an offering can be done within minutes of setting up a
          tokentory account, but many users will find it helpful to devise a marketing and
          publicity campaign to maximize the success of their token launch. Tokentory’s URL
          identification of tokens allows for much easier marketing and usability of blockchain
          tokens than has been possible up to now. Use of one’s own social media followings
          such as on Twitter and Facebook, as well as any other means of publicizing the
          availability of tokens for sale, is a good suggestion.
        </p>
        <h2 class="tokenomics-sub-title__inner" #tradeTokens>
          Trade tokens with others in speculative markets, earning
          speculative income.
        </h2>
        <p>
          Like investors in stock, currency, and commodity markets do, tokentory.com
          provides for active trading of all existing tokens where people can trade with others
          like they do on stock, currency, and commodity trading websites, earning
          speculative income by selling at higher prices than what tokens were purchased for.
          An active trading market in tokens should causes some volatility in token prices,
          which traders can take advantage of to generate long and short-term investment
          income.
        </p>
        <h2 class="tokenomics-sub-title__inner" #sellRealGoods>
          Sell real goods and services to people in exchange for tokens they
          hold, and thereby recuperate tokens which can be resold to buyers
          again at current market prices.
        </h2>
        <p>
          One good use of tokens would be to sell exclusive services or merchandise. A token
          creator may decide to reward token holders by releasing events or goods only
          buyable using the token. This allows loyal token holders to receive potential
          discounts on offerings if they purchased a token when it was lower in value and
          then they can cash it in on something more expensive. It also allows token creators
          a means for recouping tokens for resale at current value. In many cases, the value
          of the token may rise since goods and services may only be available exclusively to
          token holders.
        </p>
        <h2 class="tokenomics-sub-title__inner" #whatIsOffering>
          What Exactly is an Offering?
        </h2>
        <p>
          An Offering is a blockchain contract created by the token creator that can receive
          said tokens. This contract can represent goods and services provided by the token
          creator exclusively to token holders. The Offering contract is specific to its parent
          token, and only those tokens can be used to purchase the offering. Once tokens are
          spent on the offering, the transaction is confirmed on the blockchain acting as a
          receipt for purchase, and the tokens automatically release to the token creator’s
          wallet.
        </p>
        <h2 class="tokenomics-sub-title__inner" #offeringCycle>
          Token Creation and Offering Cycle
        </h2>
        <p>
          Each token that you create makes its own mini economy – an eco-system – allowing
          for the efficient transactions of goods and services using that token as a unit of
          exchange, and those tokens can also be sold for fiat currencies such as the US
          Dollar. This, in turn, links each eco-system to the larger national and world
          economies securely, efficiently, and inexpensively by using Tokentory.com.
        </p>
        <p>
          The possibilities are limitless, and as long as the tokens can be exchanged for
          tangible goods and services at some point – not security assets like stocks –
          commercialization of tokens can be done without regulation by the Securities and
          Exchange Commission.
        </p>
      </div>
    </div>

  </div>
