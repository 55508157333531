<div style="display: flex; height: 100%; flex-direction: column; align-items: center; justify-content: center;">
  <mat-form-field floatLabel="always" color="accent">
    <mat-label>Input accent</mat-label>
    <input matInput>
    <span matSuffix>some text</span>
    <mat-hint>hint</mat-hint>
    <mat-error>error</mat-error>
  </mat-form-field>
  <mat-form-field floatLabel="always" color="primary">
    <mat-label>Input primary</mat-label>
    <input matInput>
    <span matSuffix>some text</span>
    <mat-hint>hint</mat-hint>
    <mat-error>error</mat-error>
  </mat-form-field>
  <mat-form-field floatLabel="always" color="warn">
    <mat-label>Input warn</mat-label>
    <input matInput>
    <span matSuffix>some text</span>
    <mat-hint>hint</mat-hint>
    <mat-error>error</mat-error>
  </mat-form-field>
  <br>
  <br>
  <br>
  <mat-form-field floatLabel="always">
    <mat-label>Textarea</mat-label>
    <textarea matInput></textarea>
  </mat-form-field>

  <br>
  <br>
  <div>
    <button mat-raised-button color="primary">Button</button>
    <button mat-raised-button color="accent">Button</button>
    <button mat-raised-button color="warn">Button</button>
  </div>
  <button mat-button>Button</button>
  <button mat-flat-button>Button</button>
  <button mat-stroked-button>Button</button>
  <button mat-icon-button>Button</button>
  <button mat-fab>Button</button>
  <button mat-mini-fab>Button</button>
</div>
