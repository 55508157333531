import {Injectable} from '@angular/core';
import {ApiService} from '../../core-module/services/api-service/api.service';
import {SupportContact} from "../models/support-contact";

@Injectable({
  providedIn: 'root'
})

export class SupportService {


  constructor(private apiService: ApiService) {
  }

  getCategories() {
    return this.apiService.get<any>('support/category-choices');
  }

  getFAQ() {
    return this.apiService.get<any>('support/faq');
  }

  submitContact(data: SupportContact) {
    return this.apiService.post<SupportContact>('support', data);
  }

}
