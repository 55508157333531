import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { startWith } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  get isLoading() {
    return this.loading.asObservable().pipe(
      startWith(false)
    );
  }

  constructor() { }

  show(): void {
    this.loading.next(true);
  }

  hide(): void {
    this.loading.next(false);
  }
}
