import { Settings, SettingsService } from './../../../shared-module/services/settings/settings.service';
import {Component, OnDestroy} from '@angular/core';
import {AuthService} from '../../services/auth-service/auth.service';
import {ProfileService} from '../../../profile-module/services/profile-service/profile.service';
import {Observable, Subject} from 'rxjs';
import {map, takeUntil, tap} from 'rxjs/operators';
import {CentrifugeService} from '../../services/centrifuge-service/centrifuge.service';
import {TxProgress} from '../../../notifications-module/models/tx-progress';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import {User} from '../../../profile-module/models/user';

@Component({
  selector: 'app-desktop-shell',
  templateUrl: './desktop-shell.component.html',
  styleUrls: ['./desktop-shell.component.scss']
})
export class DesktopShellComponent implements OnDestroy {
  private onDestroyed$: Subject<void> = new Subject<void>();
  username: Observable<string>;
  progress: TxProgress = {
    color: null,
    percent: null,
    tx_hash: null
  };
  isAuthenticated: boolean;

  emptyPadding: boolean = false;
  user: User;
  hasNotFilledField: boolean;
  settings$: Observable<Settings>;

  constructor(private authService: AuthService,
              private profileService: ProfileService,
              private centrifugeService: CentrifugeService,
              private router: Router,
              private settingsService: SettingsService) {
    this.isAuthenticated = this.authService.isAuthenticated;
    this.settings$ = this.settingsService.settings$;

    this.username = profileService.getBlockchainDetails().pipe(
      map(r => r.ens)
    );

    if (this.isAuthenticated) {
      this.profileService.getUser().subscribe(user => {
        this.user = user;
        this.centrifugeService.connectWebSocket(user);
        this.profileService.checkIfFormFillOuted(user);
      });
    }
    this.profileService.getProfileFill()
      .pipe(takeUntil(this.onDestroyed$))
      .subscribe((hasNotFilledField: boolean) => {
        console.log('hasNotFilledField', hasNotFilledField);

        this.hasNotFilledField = hasNotFilledField;
      });
    this.profileService.getProgressStream()
      .pipe(takeUntil(this.onDestroyed$))
      .subscribe((progress: TxProgress) => {
        this.progress = progress;
      });
    if (this.router.url === '/transactions') this.emptyPadding = true;
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.emptyPadding = val.url === '/transactions';
      }
    });
  }

  logout(): void {
    this.authService.logout();
  }

  ngOnDestroy(): void {
    this.onDestroyed$.next();
    this.onDestroyed$.complete();
  }
}
