import { Component, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BaseComponent {

  isXS: Observable<boolean>;

  constructor(
    private breakpointObserver: BreakpointObserver
  ) {

    this.isXS = breakpointObserver.observe([Breakpoints.XSmall]).pipe(
      take(1), // TODO this works only once, screen refresh is required for router outlet to reset
      map(state => state.matches)
    );
  }
}
