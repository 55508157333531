import { Injectable } from '@angular/core';
import {ApiService} from '../api-service/api.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {Notifications} from '../../../notifications-module/models/notifications';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  private notifications$ = new BehaviorSubject<Notifications>({today: [], earlier: []});
  constructor(private apiService: ApiService) { }

  getNotifications(): void {
    this.apiService.get(`notifications/list`).subscribe((res: Notifications) => {
      this.notifications$.next(res);
    });
  }

  getNotificationsStream(): Observable<Notifications>  {
    return this.notifications$.asObservable();
  }

  markAsRead(id: string): Observable<any> {
    return this.apiService.post(`notifications/mark-as-read/${id}`, {});
  }

  markAsReadAll(): Observable<any> {
    return this.apiService.post(`notifications/mark-as-read/all`, {});
  }

  markAsHidden(id: string): Observable<any> {
    return this.apiService.post(`notifications/hide/${id}`, {});
  }

  updateNotifications(data: Notifications): void {
    this.notifications$.next(data);
  }
}
