import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { ProfileService } from "../../profile-module/services/profile-service/profile.service";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class FillProfileGuard implements CanActivate {
  constructor(private profile: ProfileService, private router: Router) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.profile.getUser().pipe(
      map((user) => {
        return (
          !ProfileService.formHasNotFilledField(user) ||
          this.router.createUrlTree(["/"])
        );
      })
    );
  }
}
