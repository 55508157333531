import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gradient-content-wrapper',
  templateUrl: './gradient-content-wrapper.component.html',
  styleUrls: ['./gradient-content-wrapper.component.scss']
})
export class GradientContentWrapperComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
