<ng-template #template>
  <router-outlet>
  </router-outlet>
</ng-template>

<app-desktop-shell fxHide.xs>
  <div *ngIf="(this.isXS|async) === false">
    <ng-container *ngTemplateOutlet="template"></ng-container>
  </div>
</app-desktop-shell>

<app-mobile-shell fxHide fxShow.xs style="width: 100%;height: 100%;max-height: 100%">
  <div *ngIf="(this.isXS|async) === true">
    <ng-container *ngTemplateOutlet="template"></ng-container>
  </div>
</app-mobile-shell>
