<app-gradient-content-wrapper>
  <app-public-toolbar></app-public-toolbar>
    <div class="policy-container">
      <div class="title-row">
        <div class="logo" fxFlex="300px" fxHide.xs>
          <img src="assets/images/logo_small.svg" alt="Tokentory Logo">
        </div>
        <div class="title">
          <h1 class="policy-title">Privacy Policy</h1>
        </div>
      </div>
      <div class="text-content">
      </div>
    </div>
</app-gradient-content-wrapper>
