import {Component, OnDestroy, OnInit} from '@angular/core';
import { ActivatedRoute, ActivationEnd, Data, Router } from '@angular/router';
import {filter, map, startWith, takeUntil, tap} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import { Location } from '@angular/common';
import { AuthService } from '../../services/auth-service/auth.service';
import {ProfileService} from "../../../profile-module/services/profile-service/profile.service";

@Component({
  selector: 'app-mobile-shell',
  templateUrl: './mobile-shell.component.html',
  styleUrls: ['./mobile-shell.component.scss']
})
export class MobileShellComponent implements OnInit, OnDestroy {
  private onDestroyed$: Subject<void> = new Subject<void>();
  actions$: Observable<Data>;
  isAuthenticated: boolean;
  hasNotFilledField: boolean;


  constructor(public router: Router,
              public location: Location,
              private profileService: ProfileService,
              public route: ActivatedRoute,
              private auth: AuthService) {
    this.isAuthenticated = auth.isAuthenticated;

    this.actions$ = this.router.events.pipe(
      filter(e => e instanceof ActivationEnd),
      startWith('initial'),
      map((x: any) => route.firstChild.firstChild.snapshot.data?.actions)
    );

    this.profileService.getProfileFill()
      .pipe(takeUntil(this.onDestroyed$))
      .subscribe((hasNotFilledField: boolean) => {
        this.hasNotFilledField = hasNotFilledField;
      });

  }

  ngOnInit(): void { }

  actionClick($event: MouseEvent, a: any) {
    if (a.link === 'back') {
      this.location.back();
    } else {
      this.router.navigate([a.link], {relativeTo: this.route}).then(r => r);
    }
  }

  ngOnDestroy() {
    this.onDestroyed$.next();
    this.onDestroyed$.complete();
  }
}
