  <app-public-toolbar [showSignupLink]="true"></app-public-toolbar>
  <div fxLayout="row">
    <div  class="support-menu" fxHide.xs>
      <div fxLayout="row" fxLayoutAlign="space-between end">
        <img src="assets/images/tokn_logo.svg" alt="Tokentory Logo">
      </div>
      <mat-divider class="menu-divider"></mat-divider>
    </div>

    <div class="support-container">
      <div class="support-content">
        <h1 class="support-title">Contact Support</h1>
        <p style="margin-top: 50px">Help with common questions or issues can typically be found on our <a
            routerLink="/faq">Frequently Asked
            Questions</a> page</p>
        <p>If you still cannot find the answer you need, please fill out and submit the form below and we will gladly get
          back to you in a timely manner.</p>
        <form [formGroup]="form">
          <mat-form-field class="support-form-field">
            <mat-label>Please select the category which best fits the question/issue you have.</mat-label>
            <mat-select formControlName="category">
              <mat-option *ngFor="let category of categories" [value]="category.value">{{category.name}}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="c-textarea">
            <mat-label class="text-area-label">Please describe your question/issue with in as much detail as possible so
              that our team can help
              you find an accurate answer as quickly as possible.
            </mat-label>
            <textarea matInput formControlName="description" rows="10"></textarea>
          </mat-form-field>

          <mat-form-field class="support-form-field">
            <mat-label>Confirm Email Address</mat-label>
            <input matInput formControlName="email" placeholder="Enter your email">
            <mat-error *ngIf="form.get('email').hasError('invalid')">{{form.get('email').getError('invalid')}}</mat-error>
            <mat-error *ngIf="form.get('email').hasError('email')">Email is not valid.</mat-error>
            <mat-error *ngIf="form.get('email').hasError('whiteSpace')">This field can not be empty</mat-error>
          </mat-form-field>
          <br>
          <button class="submit" mat-raised-button color="primary" [disabled]="form.invalid"
            (click)="submit()">Submit</button>
        </form>
      </div>
    </div>
  </div>
