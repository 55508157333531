import { BehaviorSubject } from "rxjs";
import { ApiService } from "./../../../core-module/services/api-service/api.service";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class SettingsService {
  private settings: BehaviorSubject<Settings> = new BehaviorSubject<Settings>({
    title: "",
    scanner_url: "",
    scanner_button_text: "",
    token_ens_name: "",
    user_ens_name: "",
  });

  public settings$ = this.settings.asObservable();

  constructor(private apiService: ApiService) {
    this.getSettings().subscribe((settings) => {
      this.settings.next(settings);
    });
  }

  private getSettings() {
    return this.apiService.get<Settings>("settings");
  }
}

export interface Settings {
  title: string;
  scanner_url: string;
  scanner_button_text: string;
  token_ens_name: string;
  user_ens_name: string;
}
