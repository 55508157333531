import {Component, OnDestroy, OnInit} from '@angular/core';
import {NotificationsService} from '../../services/notifications-service/notifications.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {Router} from '@angular/router';
import {NotificationItem, Notifications} from '../../../notifications-module/models/notifications';
import {ProfileService} from '../../../profile-module/services/profile-service/profile.service';

@Component({
  selector: 'app-notifications-dropdown',
  templateUrl: './notifications-dropdown.component.html',
  styleUrls: ['./notifications-dropdown.component.scss']
})
export class NotificationsDropdownComponent implements OnInit, OnDestroy {
  private onDestroyed$: Subject<void> = new Subject<void>();
  notifications: Notifications;
  notReadNotificationsCount: number;

  constructor(private notificationsService: NotificationsService,
              private router: Router,
              private profileService: ProfileService) {
  }

  ngOnInit(): void {
    this.notificationsService.getNotifications();
    this.notificationsService.getNotificationsStream()
      .pipe(takeUntil(this.onDestroyed$))
      .subscribe((notifications: Notifications) => {
        this.notifications = notifications;
        this.notReadNotificationsCount = [
          ...this.notifications.today,
          ...this.notifications.earlier]
          .filter(item => !item.is_read).length;
      });
  }

  navigateToNotificationList(notification: NotificationItem, event): void {
    if (event.target.classList.contains('sub-title') && notification?.extra_params?.origin_id) {
      this.redirectToPage(notification);
      return;
    }
    if (!notification.is_read) {
      const progressValue = this.profileService.getProgressValue();
      if (progressValue.tx_hash === notification.tx_hash && progressValue.percent === 100) this.profileService.clearProgressData();
      this.markAsReadAll();
    }
    this.router.navigate(['notifications', notification.id]);
  }

  markAsReadAll(): void {
    this.notificationsService.markAsReadAll().subscribe(() => {
      this.notifications.today.forEach(item => item.is_read = true);
      this.notifications.earlier.forEach(item => item.is_read = true);
      this.notificationsService.updateNotifications(this.notifications);
    });
  }

  redirectToPage(notification: NotificationItem): void {
    if (!notification?.extra_params?.origin_id) return;
    switch (notification?.extra_params?.origin) {
      case 'token':
        this.router.navigate(['directory/token/', notification?.extra_params?.origin_id]);
        break;
      case 'offering':
        this.router.navigate([
          `factory/${notification?.extra_params?.token_id}/offering-overview/${notification?.extra_params?.origin_id}`
        ]);
        break;
    }
  }

  ngOnDestroy(): void {
    this.onDestroyed$.next();
    this.onDestroyed$.complete();
  }
}
