  <app-public-toolbar [showSignupLink]="true"></app-public-toolbar>
  <div fxLayout="row">
    <div class="faq-menu" fxHide.xs>
      <div fxLayout="row" fxLayoutAlign="space-between end">

        <img src="assets/images/tokn_logo.svg" alt="Tokentory Logo">
      </div>
      <mat-divider class="menu-divider"></mat-divider>
      <mat-selection-list [multiple]="false" dense style="margin-top: 24px;"  class="left-menu-list">
        <mat-list-option *ngFor="let item of faq; let i = index"
                         (click)="scroll(i)">{{item.title | titlecase}}</mat-list-option>
      </mat-selection-list>
    </div>

    <div class="faq-container">
      <div class="faq-content">
        <h1 class="faq-title">FAQ</h1>
        <ng-container *ngFor="let item of faq; let i = index">
          <h2 [attr.id]="i" [innerHTML]="item.title | titlecase"></h2>
          <p [innerHTML]="item.text"></p>
        </ng-container>
      </div>
    </div>
  </div>
