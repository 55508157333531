<div class="toolbar" fxHide.xs>
  <ul>
    <li *ngIf="auth.isAuthenticated">
      <a routerLink="/home" (click)="$event.preventDefault();" routerLinkActive="current">HOME</a>
    </li>
    <li  *ngIf="!auth.isAuthenticated">
      <a routerLink="/auth/start-page" (click)="$event.preventDefault();" routerLinkActive="current">HOME</a>
    </li>
    <li>
      <a routerLink="/tokenomics" (click)="$event.preventDefault();" routerLinkActive="current">TOKENOMICS</a>
    </li>
    <li>
      <a routerLink="/faq" (click)="$event.preventDefault();" routerLinkActive="current">FAQ</a>
    </li>
    <li>
      <a routerLink="/support" (click)="$event.preventDefault();" routerLinkActive="current">SUPPORT</a>
    </li>
  </ul>

  <div *ngIf="!auth.isAuthenticated" class="auth-buttons">
    <a *ngIf="showSignupLink" routerLink="/auth/sign-up-invite-code" class="signup-link" (click)="$event.preventDefault();">Sign-up</a>
    <button mat-flat-button color="primary" routerLink="/auth/sign-in">Login</button>
  </div>
</div>
