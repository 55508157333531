import {Injectable} from '@angular/core';
import {ApiService} from '../../../core-module/services/api-service/api.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import {BlockchainDetails} from '../../models/blockchain-details';
import {User} from '../../models/user';
import { tap } from 'rxjs/operators';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TxProgress} from '../../../notifications-module/models/tx-progress';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  public user: User;
  private details: BlockchainDetails;
  private progress$ = new BehaviorSubject<TxProgress>({
    color: null,
    percent: null,
    tx_hash: null
  });

  private profileFill$: BehaviorSubject<boolean> = new BehaviorSubject(null);

  public hasNotFilledField: boolean;

  constructor(
    private apiService: ApiService,
    private snackBar: MatSnackBar
  ) {
  }

  getUser(): Observable<User> {
    if (this.user) {
      return of(this.user);
    }

    return this.apiService.get<User>('accounts/profile').pipe(
      tap(u => {
        this.user = u;
      })
    );
  }

  getBlockchainDetails(): Observable<BlockchainDetails> {
    if (this.details) {
      return of(this.details);
    }

    return this.apiService.get<BlockchainDetails>('accounts/blockchain').pipe(
      tap(d => this.details = d)
    );
  }

  updateProfile(data: Partial<User>): Observable<User> {
    return this.apiService.patch<User>('accounts/profile', data).pipe(
      tap(u => this.snackBar.open('Details are successfully updated', 'OK')),
      tap(u => this.user = u)
    );
  }

  clearCache(): void {
    this.user = null;
    this.details = null;
  }

  changePassword(body: { old_password: string, new_password1: string, new_password2: string }): Observable<any> {
    return this.apiService.post('accounts/change_password', body);
  }

  getProgressStream(): Observable<TxProgress>  {
    return this.progress$.asObservable();
  }

  getProgressValue(): TxProgress  {
    return this.progress$.getValue();
  }

  updateProgress(data: TxProgress): void {
    this.progress$.next(data);
  }

  clearProgressData(): void {
    this.progress$.next({
      color: null,
      percent: 0,
      tx_hash: null
    });
  }

  getProfileFill(): Observable<any> {
    return this.profileFill$.asObservable();
  }

  updateProfileFill(hasNotFilledField): void {
    this.hasNotFilledField = hasNotFilledField
    this.profileFill$.next(hasNotFilledField);
  }

  checkIfFormFillOuted(user: User): void {
    this.updateProfileFill(ProfileService.formHasNotFilledField(user));
  }

  public static formHasNotFilledField(user: User): boolean {
    let hasNotFilledField = false;
    for (const key in user) {
      if (!user[key]
        && key !== 'address_line_2'
        && key !== 'language'
        && key !== 'total_offerings'
        && key !== 'total_tokens'
        && key !== 'is_creator'
        && key !== 'paypal_email') {
        hasNotFilledField = true;
      }
    }
    return hasNotFilledField;
  }

}
