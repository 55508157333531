  <mat-toolbar style="justify-content: space-between; background-color: #242424;padding: 38px 23px;">

    <button *ngFor="let a of actions$|async" mat-icon-button (click)="actionClick($event,a)">
      <mat-icon style="color: #44FA1F">{{a.icon}}</mat-icon>
    </button>

    <img src="/assets/images/TokentoryLogo.svg" height="34" alt="TokentoryLogo">
<!--    <button mat-icon-button >-->
<!--      <mat-icon matBadge="8" matBadgePosition="above after" routerLink="/notifications" matBadgeColor="primary">notifications</mat-icon>-->
<!--    </button>-->
    <!--// todo replace this image in app-notifications-dropdown-->
<!--    <img src="assets/icons/notificIcon.svg" alt="">-->
    <app-notifications-dropdown *ngIf="isAuthenticated"></app-notifications-dropdown>

    <button routerLink="/auth/sign-in" mat-icon-button *ngIf="!isAuthenticated">
      <mat-icon>login</mat-icon>
    </button>
  </mat-toolbar>
  <div style="height: calc(100vh - 136px);overflow:auto;padding-bottom: 80px; background-color: #242424">
    <ng-content></ng-content>
  </div>

  <nav class="bottom-nav">
    <a [routerLink]="isAuthenticated ? '/directory' : '/auth/sign-in'" routerLinkActive="current" *ngIf="!hasNotFilledField">
      <mat-icon svgIcon="directory-icon" style="width: 32px;height: 32px"></mat-icon>
    </a>
    <a [routerLink]="isAuthenticated ? '/factory' : '/auth/sign-in'" routerLinkActive="current" *ngIf="!hasNotFilledField">
      <mat-icon svgIcon="tokentory-icon" style="width: 32px;height: 32px"></mat-icon>
    </a>
    <a [routerLink]="isAuthenticated ? '/wallet' : '/auth/sign-in'" routerLinkActive="current" *ngIf="!hasNotFilledField">
      <mat-icon svgIcon="wallet-icon" style="width: 32px;height: 32px"></mat-icon>
    </a>
    <a [routerLink]="isAuthenticated ? '/profile' : '/auth/sign-in'" routerLinkActive="current">
      <mat-icon svgIcon="person-icon" style="width: 32px;height: 32px"></mat-icon>
    </a>
  </nav>
