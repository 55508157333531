import {Injectable} from '@angular/core';
import {NotificationsService} from '../notifications-service/notifications.service';
import * as Centrifuge from 'centrifuge';
import {environment} from '../../../../environments/environment';
import {User} from '../../../profile-module/models/user';
import {Notifications} from '../../../notifications-module/models/notifications';
import {ProfileService} from '../../../profile-module/services/profile-service/profile.service';

@Injectable({
  providedIn: 'root'
})
export class CentrifugeService {
  private cf: any;
  notifications: Notifications;

  constructor(public notificationsService: NotificationsService, private profileService: ProfileService) {
  }

  connectWebSocket(user: User) {
    if (this.cf) {
      return;
    }
    this.cf = new Centrifuge(`${environment.centrifugeUrl}`);

    this.cf.setToken(user.ws_token);

    this.cf.subscribe(`notifications#${user.uuid}`, (centrifugeResponse) => {
      this.storeData(centrifugeResponse.data);
    });
    this.cf.subscribe(`tx-progress#${user.uuid}`, (centrifugeResponse) => {
      this.profileService.updateProgress(centrifugeResponse.data);
    });

    this.cf.connect();

    this.notificationsService.getNotificationsStream().subscribe(res => {
      this.notifications = res;
    });
  }

  disconnectWebSocket(): void {
    if (!this.cf) return;
    this.cf.removeAllListeners();
    this.cf.disconnect();
    this.cf = null;
  }

  private storeData(data): void {
    this.notifications.today.unshift(data);
    this.notificationsService.updateNotifications(this.notifications);
  }
}
