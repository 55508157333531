import { AbstractControl, ValidationErrors } from "@angular/forms";

export class CustomValidators {
  static passwordMatch(control: AbstractControl): ValidationErrors | null {
    const password = control.get("password");
    const confirmPassword = control.get("confirm_password");
    if (
      !password ||
      !confirmPassword ||
      !password.value ||
      !confirmPassword.value
    ) {
      return null;
    }
    const error =
      password.value === confirmPassword.value
        ? null
        : { invalid: "Passwords don't match." };
    confirmPassword.setErrors(error);
    return error;
  }
  static whiteSpaceValidator(
    control: AbstractControl
  ): { whiteSpace: boolean } | null {



    if (control.value && control.value.trim().length === 0) {
      return { whiteSpace: true };
    }
    return null;
  }
}
