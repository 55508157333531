import { FormGroup } from '@angular/forms';

export class Utils {
  static setFormErrors(form: FormGroup, e: {[key: string]: {}}, skippValidity?: boolean): void {
    for (const field in e) {
      if (form.controls.hasOwnProperty(field)) {
        form.get(field).setErrors({invalid: e[field][0]});
        form.get(field).markAsTouched();
      }
    }

    if(!skippValidity) form.updateValueAndValidity({emitEvent: true});
  }
}
