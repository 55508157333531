import { Component, OnInit } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import { SupportService } from "../../services/support.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { catchError } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";
import { Utils } from "../../../core-module/classes/utils";
import { of } from "rxjs";
import { Router } from "@angular/router";
import { CustomValidators } from "src/app/core-module/validators/custom-validators";

@Component({
  selector: "app-support-form",
  templateUrl: "./support-form.component.html",
  styleUrls: ["./support-form.component.scss"],
})
export class SupportFormComponent implements OnInit {
  form: FormGroup;
  categories = [];

  constructor(
    private fb: FormBuilder,
    private snackbar: MatSnackBar,
    private router: Router,
    private supportService: SupportService
  ) {
    this.form = fb.group({
      category: ["", [Validators.required]],
      description: ["", [Validators.required]],
      email: ["", [Validators.required, CustomValidators.whiteSpaceValidator]],
    });
  }

  ngOnInit(): void {
    this.supportService.getCategories().subscribe((res) => {
      for (const key in res) {
        this.categories.push({
          value: key,
          name: res[key],
        });
      }
    });
  }

  submit(): void {
    this.supportService
      .submitContact(this.form.getRawValue())
      .pipe(
        catchError((e: HttpErrorResponse) => {
          Utils.setFormErrors(this.form, e.error);
          return of(null);
        })
      )
      .subscribe((res) => {
        if (!res) return;
        this.snackbar.open("Success", "Ok");
        this.router.navigate(["/"]);
      });
  }
}
